import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError } from "../Notification";

const token = Cookies.get("token");

export const commonApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get admin roles
    getAdminRoles: builder.query({
      query: (arg) => ({
        url: "/core/common/list/admin-roles",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => response.result.list,
      transformErrorResponse: (error) => {
        notifyError(error?.data?.message || error.message);
        return error;
      },
      providesTags: ["AdminRoles"],
    }),

    // Get companies list
    getCompaniesList: builder.query({
      query: (arg) => ({
        url: "/core/common/list/companies",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => response.result.list,
      transformErrorResponse: (error) => {
        notifyError(error?.data?.message || error.message);
        return error;
      },
      providesTags: ["Companies"],
    }),

    // Get subscription list
    getSubscriptionList: builder.query({
      query: (arg) => ({
        url: "/core/common/list/subscription-status",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => response.result.list,
      transformErrorResponse: (error) => {
        notifyError(error?.data?.message || error.message);
        return error;
      },
      providesTags: ["Subscriptions"],
    }),

    // Get subscription methods
    getSubscriptionMethods: builder.query({
      query: (arg) => ({
        url: "/core/common/list/subscription-payment-method",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => response.result.list,
      transformErrorResponse: (error) => {
        notifyError(error?.data?.message || error.message);
        return error;
      },
      providesTags: ["SubscriptionMethods"],
    }),
  }),
});

// Export hooks for usage in components
export const {
  useGetAdminRolesQuery,
  useGetCompaniesListQuery,
  useGetSubscriptionListQuery,
  useGetSubscriptionMethodsQuery,
} = commonApi;
