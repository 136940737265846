import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const DeleteItem = ({
  data,
  onClose,
  title,
  deleteFn,
  selectedRowIds,
  type,
}) => {
  const [t] = useTranslation();

  const handleDelete = (e) => {
    e.preventDefault();
    if (type === "all" && selectedRowIds?.length > 0) {
      deleteFn(selectedRowIds);
    } else {
      deleteFn(data.id);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      p={2}
    >
      {type === "all" && (!selectedRowIds || selectedRowIds.length <= 0) ? (
        <div
          className="p-2"
          style={{ color: "red", fontSize: "22px", fontWeight: 700 }}
        >
          {t("There Are No Rows Selected")}
        </div>
      ) : (
        <div className="box-popdelete">
          <div className="box-icon-delete">
            <CloseIcon />
          </div>

          <Typography align="center" sx={{ py: 4, px: 2 }} variant="h5">
            {t("Are you sure you want to perform this deletion")}?
          </Typography>

          <div className="btn-list">
            <button className="btn btn-delete" onClick={handleDelete}>
              {t("Delete")}
            </button>
            <button
              className="btn btn-cancel"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClose();
              }}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default DeleteItem;
