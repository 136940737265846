import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Dynamically set the base URL by combining the current origin with your API path
const dynamicBaseUrl = `${window.location.origin}/backend/public/api`;
console.log("dynamicBaseUrl", dynamicBaseUrl);
console.log("window.location.origin", window.location.origin);
export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl:
      window.location.origin == "http://localhost:8080"
        ? "https://www.dentistsavenue.com/e-smile/public/api"
        : "https://www.dentistsavenue.com/e-smile/public/api",
  }),
  endpoints: () => ({}),
});
