import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");

export const categoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all categories
    getCategories: builder.query({
      query: (arg) => ({
        url: "/admin-panal/categories",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Categories List Response:", response);
        return response.result.categories.data;
      },
      providesTags: ["Categories"],
    }),

    // Get single category
    getCategoryById: builder.query({
      query: (arg) => ({
        url: `/admin-panal/categories/${arg.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Category Detail Response:", response);
        return response.result;
      },
      providesTags: (result, error, arg) => [{ type: "Category", id: arg.id }],
    }),

    // Create category
    createCategory: builder.mutation({
      query: (arg) => ({
        url: "/admin-panal/categories",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Create Category Response:", response);
        return response;
      },
      invalidatesTags: ["Categories"],
    }),

    // Update category
    updateCategory: builder.mutation({
      query: (arg) => ({
        url: `/admin-panal/categories/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Update Category Response:", response);
        return response;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: arg.id },
        "Categories",
      ],
    }),

    // Delete category
    deleteCategory: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          // Make separate API calls for each ID
          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/admin-panal/categories/${id}`,
              method: "DELETE",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          // Wait for all delete operations to complete
          const results = await Promise.all(deletePromises);
          console.log("Delete Category Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Categories"],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryByIdQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;
