import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");

export const subscriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all subscriptions
    getSubscriptions: builder.query({
      query: (arg) => ({
        url: "/admin-panal/subscriptions",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Subscriptions List Response:", response);
        return response.result.subscriptions.data;
      },
      providesTags: ["Subscriptions"],
    }),

    // Get subscription status list
    getSubscriptionStatusList: builder.query({
      query: (arg) => ({
        url: "/core/common/list/subscription-status",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Subscription Status List Response:", response);
        return response.result.list;
      },
      providesTags: ["SubscriptionStatus"],
    }),

    // Get subscription payment methods
    getSubscriptionPaymentMethods: builder.query({
      query: (arg) => ({
        url: "/core/common/list/subscription-payment-method",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Subscription Payment Methods Response:", response);
        return response.result.list;
      },
      providesTags: ["PaymentMethods"],
    }),

    // Get single subscription
    getSubscriptionById: builder.query({
      query: (arg) => ({
        url: `/admin-panal/subscriptions/${arg.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Subscription Detail Response:", response);
        return response.result.user_subscription;
      },
      providesTags: (result, error, arg) => [
        { type: "Subscription", id: arg.id },
      ],
    }),

    // Create subscription
    createSubscription: builder.mutation({
      query: (arg) => ({
        url: "/admin-panal/subscriptions",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Create Subscription Response:", response);
        return response;
      },
      invalidatesTags: ["Subscriptions"],
    }),

    // Update subscription
    updateSubscription: builder.mutation({
      query: (arg) => ({
        url: `/admin-panal/subscriptions/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Update Subscription Response:", response);
        return response;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Subscription", id: arg.id },
        "Subscriptions",
      ],
    }),

    // Delete subscription
    deleteSubscription: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          // Make separate API calls for each ID
          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/admin-panal/subscriptions/${id}`,
              method: "DELETE",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          // Wait for all delete operations to complete
          const results = await Promise.all(deletePromises);
          console.log("Delete Subscription Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Subscriptions"],
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useGetSubscriptionByIdQuery,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetSubscriptionStatusListQuery,
  useGetSubscriptionPaymentMethodsQuery,
} = subscriptionApi;
