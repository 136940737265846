import { DialogTitle, Switch, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import { notifyError, notifysuccess } from "../../Notification";
import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useGetCompanyByIdQuery,
} from "../../services/companiesApi";

function PopUpCompany({ type, handleClose, data }) {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [inp, setInp] = useState({ active: 0, auto_renew: 0 });

  // RTK Query hooks
  const [createCompany, { isLoading: isCreating }] = useCreateCompanyMutation();
  const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation();

  useEffect(() => {
    if ((type === "edit" || type === "view") && data) {
      setInp(data);
    }
  }, [data, type]);

  const {
    data: singleData,
    isLoading: loadingSingle,
    error: errorSingle,
  } = useGetCompanyByIdQuery(
    { id: data?.id, lang: i18n.language },
    { skip: type !== "edit" }
  );

  const getValue = (e) => {
    setInp((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSwitchChange = (e, name) => {
    setInp((prev) => ({
      ...prev,
      [name]: e.target.checked ? 1 : 0,
    }));
  };

  const onsubmitfn = async (e) => {
    e.preventDefault();
    try {
      if (type === "add") {
        const result = await createCompany({
          data: inp,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
          handleClose();
        }
      }
      if (type === "edit") {
        const newdata = {
          ...inp,
          _method: "PUT",
        };
        const result = await updateCompany({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
          handleClose();
        }
      }
    } catch (error) {
      notifyError(error?.data?.message || "An error occurred");
      console.error("Error:", error);
    }
  };

  if (errorSingle) {
    notifyError(errorSingle?.data?.message || "Error loading company data");
  }

  return (
    <Fragment>
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            borderBottom: "1px solid lightgray",
            paddingBottom: "2px",
            marginBottom: "30px",
          }}
        >
          {type === "edit" ? t("Edit Company") : t("Add Company")}
        </Typography>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row" onSubmit={onsubmitfn}>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <TextField
                  type="text"
                  label={t("name")}
                  name="name"
                  required
                  defaultValue={type === "edit" ? singleData?.name : ""}
                  onChange={getValue}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group">
                <TextField
                  type="email"
                  label={t("Email")}
                  name="email"
                  required
                  defaultValue={type === "edit" ? singleData?.email : ""}
                  onChange={getValue}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group">
                <TextField
                  type="number"
                  label={t("Tax No")}
                  name="tax_no"
                  required
                  defaultValue={type === "edit" ? singleData?.tax_no : ""}
                  onChange={getValue}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group">
                <TextField
                  type="number"
                  label={t("Commercial Registration No")}
                  name="commercial_registration_no"
                  required
                  defaultValue={
                    type === "edit"
                      ? singleData?.commercial_registration_no
                      : ""
                  }
                  onChange={getValue}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group">
                <p>
                  {t("Auto Renew")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    type === "edit" && singleData?.auto_renew == 1
                  }
                  onChange={(event) => handleSwitchChange(event, "auto_renew")}
                />
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group">
                <p>
                  {t("Active")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={type === "edit" && singleData?.active == 1}
                  onChange={(event) => handleSwitchChange(event, "active")}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {isCreating || isUpdating ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </DialogTitle>
    </Fragment>
  );
}

export default PopUpCompany;
