import axios from "axios";
import { BASE_URL } from "../apiConfig"; // Adjust the path based on your project structure
import { notifyError, notifysuccess } from "../Notification";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export let createAdmin = createAsyncThunk(
  "admin/createAdmin",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/admin-panal/admins`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAlladmins = createAsyncThunk(
  "admin/getalldmins",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/admin-panal/admins`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      console.log("data", data.data.result.admins.data);
      return data.data.result.admins.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteadmin = createAsyncThunk(
  "admin/deleteadmin",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/admin-panal/admins/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleAdmin = createAsyncThunk(
  "admin/getSingleAdmin",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/admin-panal/admins/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });

      return data.data.result.admin;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updateAdmin = createAsyncThunk(
  "admin/updateAdmin",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(
        `${BASE_URL}/admin-panal/admins/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let ChangeStatus = createAsyncThunk(
  "ChangeStatus/updateAdmin1",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    console.log("token", token);
    try {
      let data = await axios.get(
        `${BASE_URL}/admin-panal/${arg.name}/toggle-active/${arg.id}?active=${arg.active}`,
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          },
        }
      );
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updatePassword = createAsyncThunk(
  "admin/updatePassword",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store.
    try {
      let data = await axios.post(`${BASE_URL}/updatePassword`, arg, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let adminslice = createSlice({
  name: "admin",
  initialState: {
    loadingroles: false,
    data: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    roles: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    errorAll: false,
    errorSingle: false,
  },
  reducers: {
    resetAlluserAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleDataAdmins: (state, action) => {
      state.singleData = action.payload;
      state.dataCreate = action.payload;
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //createAdmin

    [createAdmin.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createAdmin.fulfilled]: (state, action) => {
      state.error = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createAdmin.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
    //updatePassword

    [updatePassword.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.error = false;
      // state.data = action.payload;
      state.loading = false;
    },
    [updatePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updateAdmin

    [updateAdmin.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updateAdmin.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.dataUpdate = action.payload;
      state.error = false;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updateAdmin.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSingleAdmin

    [getSingleAdmin.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleAdmin.fulfilled]: (state, action) => {
      state.singleData = action.payload;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleAdmin.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deleteadmin

    [deleteadmin.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteadmin.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deleteadmin.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAlladmins

    [getAlladmins.pending]: (state, action) => {
      state.loading = true;
      state.singleData = "";
      state.dataCreate = false;
      state.dataUpdate = false;
      state.errorAll = false;
      state.data = false;
    },
    [getAlladmins.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.errorAll = false;
      state.loading = false;
    },
    [getAlladmins.rejected]: (state, action) => {
      state.loading = false;
      state.errorAll = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default adminslice.reducer;
export let { resetAlluserAction, resetsingleDataAdmins } = adminslice.actions;
