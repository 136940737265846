import {
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import ResponsiveDateRangePickers from "../../components/DateRange/DateRange";
import { notifyError, notifysuccess } from "../../Notification";
import { format } from "date-fns";
import {
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useGetSubscriptionByIdQuery,
  useGetSubscriptionStatusListQuery,
  useGetSubscriptionPaymentMethodsQuery,
} from "../../services/subscriptionApi";
import { useGetCompaniesListQuery } from "../../services/companiesApi";

function PopUpSubscription({ type, handleClose, data }) {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [inp, setInp] = useState({
    active: 0,
    renew: 0,
    free_trial: 0,
  });
  const [dateSelected, setDateSelected] = useState("");

  // RTK Query hooks
  const [createSubscription, { isLoading: isCreating }] =
    useCreateSubscriptionMutation();
  const [updateSubscription, { isLoading: isUpdating }] =
    useUpdateSubscriptionMutation();

  const { data: companies, isLoading: loadingCompanies } =
    useGetCompaniesListQuery({
      lang: i18n.language,
    });

  console.log("companiescompanies", companies);
  const { data: statusList, isLoading: loadingStatus } =
    useGetSubscriptionStatusListQuery({
      lang: i18n.language,
    });

  const { data: paymentMethods, isLoading: loadingMethods } =
    useGetSubscriptionPaymentMethodsQuery({
      lang: i18n.language,
    });

  const {
    data: singleData,
    isLoading: loadingSingle,
    error: errorSingle,
  } = useGetSubscriptionByIdQuery(
    { id: data?.id, lang: i18n.language },
    { skip: type !== "edit" }
  );

  useEffect(() => {
    if ((type === "edit" || type === "view") && data) {
      setInp(data);
    }
  }, [data, type]);

  const getValue = (e) => {
    setInp((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSwitchChange = (e, name) => {
    setInp((prev) => ({
      ...prev,
      [name]: e.target.checked ? 1 : 0,
    }));
  };

  const onsubmitfn = async (e) => {
    e.preventDefault();
    try {
      if (type === "add") {
        if (!dateSelected) {
          notifyError("Choose Date please");
          return;
        }
        if (!dateSelected[0]?.startDate) {
          notifyError("Choose Start Date please");
          return;
        }
        if (!dateSelected[0]?.endDate) {
          notifyError("Choose End Date please");
          return;
        }

        const formData = new FormData();

        // Append basic fields
        formData.append("company_id", inp.company_id);
        formData.append(
          "start_date",
          format(dateSelected[0].startDate, "yyyy-MM-dd")
        );
        formData.append(
          "end_date",
          format(dateSelected[0].endDate, "yyyy-MM-dd")
        );
        formData.append("active", inp.active);
        formData.append("renew", inp.renew);
        formData.append("free_trial", inp.free_trial);

        // Append transaction fields
        formData.append(
          "user_subscription_transactions[0][status]",
          inp["user_subscription_transactions[0][status]"]
        );
        formData.append(
          "user_subscription_transactions[0][payment_method]",
          inp["user_subscription_transactions[0][payment_method]"]
        );
        formData.append(
          "user_subscription_transactions[0][cost]",
          inp["user_subscription_transactions[0][cost]"]
        );

        const result = await createSubscription({
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
          handleClose();
        }
      }

      if (type === "edit") {
        const formData = new FormData();

        // Append method for PUT request
        formData.append("_method", "PUT");

        // Append basic fields
        formData.append("company_id", inp.company_id);
        if (dateSelected) {
          formData.append(
            "start_date",
            format(dateSelected[0].startDate, "yyyy-MM-dd")
          );
          formData.append(
            "end_date",
            format(dateSelected[0].endDate, "yyyy-MM-dd")
          );
        }
        formData.append("active", inp.active);
        formData.append("renew", inp.renew);
        formData.append("free_trial", inp.free_trial);

        // Append transaction fields
        if (inp["user_subscription_transactions[0][status]"]) {
          formData.append(
            "user_subscription_transactions[0][status]",
            inp["user_subscription_transactions[0][status]"]
          );
        }
        if (inp["user_subscription_transactions[0][payment_method]"]) {
          formData.append(
            "user_subscription_transactions[0][payment_method]",
            inp["user_subscription_transactions[0][payment_method]"]
          );
        }
        if (inp["user_subscription_transactions[0][cost]"]) {
          formData.append(
            "user_subscription_transactions[0][cost]",
            inp["user_subscription_transactions[0][cost]"]
          );
        }

        const result = await updateSubscription({
          id: singleData.id,
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
          handleClose();
        }
      }
    } catch (error) {
      notifyError(error?.data?.message || "An error occurred");
      console.error("Error:", error);
    }
  };

  if (errorSingle) {
    notifyError(
      errorSingle?.data?.message || "Error loading subscription data"
    );
  }

  return (
    <Fragment>
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            borderBottom: "1px solid lightgray",
            paddingBottom: "2px",
            marginBottom: "30px",
          }}
        >
          {type === "edit" ? t("Edit Subscription") : t("Add Subscription")}
        </Typography>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row" onSubmit={onsubmitfn}>
            <div className="col-md-12 mb-3">
              <FormControl fullWidth>
                <InputLabel id="company">{t("Company")}</InputLabel>
                <Select
                  labelId="Company"
                  name="company_id"
                  required
                  defaultValue={type === "edit" ? singleData?.company.id : ""}
                  label="Company"
                  onChange={getValue}
                >
                  {loadingCompanies ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    companies?.map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <InputLabel id="Date">{t("Date")}</InputLabel>
                <ResponsiveDateRangePickers
                  setDateSelected={setDateSelected}
                  singleData={singleData}
                  type={type}
                />
              </div>
            </div>

            <div className="col-md-12 mb-3">
              <FormControl fullWidth>
                <InputLabel id="Status">{t("Status")}</InputLabel>
                <Select
                  labelId="Status"
                  name="user_subscription_transactions[0][status]"
                  required
                  defaultValue={
                    type === "edit"
                      ? singleData?.user_subscription_transactions[0].status
                      : ""
                  }
                  label="Status"
                  onChange={getValue}
                >
                  {loadingStatus ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    statusList?.map((status) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-12 mb-3">
              <FormControl fullWidth>
                <InputLabel id="payment-method">
                  {t("Payment method")}
                </InputLabel>
                <Select
                  labelId="payment-method"
                  name="user_subscription_transactions[0][payment_method]"
                  required
                  defaultValue={
                    type === "edit"
                      ? singleData?.user_subscription_transactions[0]
                          .payment_method
                      : ""
                  }
                  label="Payment method"
                  onChange={getValue}
                >
                  {loadingMethods ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    paymentMethods?.map((method) => (
                      <MenuItem key={method.id} value={method.id}>
                        {method.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-12 mb-3">
              <div className="form-group">
                <TextField
                  type="number"
                  label={t("Cost")}
                  name="user_subscription_transactions[0][cost]"
                  required
                  defaultValue={
                    type === "edit"
                      ? singleData?.user_subscription_transactions[0].cost
                      : ""
                  }
                  onChange={getValue}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="form-group">
                <p>
                  {t("Renew")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={type === "edit" && singleData?.renew == 1}
                  onChange={(event) => handleSwitchChange(event, "renew")}
                />
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="form-group">
                <p>
                  {t("Free Trial")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={
                    type === "edit" && singleData?.free_trial == 1
                  }
                  onChange={(event) => handleSwitchChange(event, "free_trial")}
                />
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <div className="form-group">
                <p>
                  {t("Active")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={type === "edit" && singleData?.active == 1}
                  onChange={(event) => handleSwitchChange(event, "active")}
                />
              </div>
            </div>

            <div className="btn-lest mt-3">
              {isCreating || isUpdating ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </DialogTitle>
    </Fragment>
  );
}

export default PopUpSubscription;
