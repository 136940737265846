import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import ActionIcons from "../../components/ActionIcons/ActionIcons";
import ModalComponent from "../../utils/ModalComponent";
import DeleteItem from "../../components/DeleteItem/DeleteItem";
import {
  useGetRolesQuery,
  useDeleteRoleMutation,
} from "../../services/roleApi";
import { notifyError, notifysuccess } from "../../Notification";
import { useNavigate } from "react-router-dom";

function MainRoles() {
  const [t, i18n] = useTranslation();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [open, setOpen] = useState({ status: false, type: "", data: null });

  // RTK Query hooks
  const {
    data: roles,
    isLoading,
    error: errorAll,
  } = useGetRolesQuery({
    lang: i18n.language,
  });

  const [deleteRole] = useDeleteRoleMutation();
  let navigate = useNavigate();
  const handleEdit = (params) => {
    navigate(`/roles-permision/edit/${params.row.id}`); // Navigate to edit page
  };

  const handleView = (params) => {
    navigate(`/roles-permision/view/${params.row.id}`); // Navigate to view page
  };

  const handleDelete = (params) => {
    setOpen({ status: true, type: "delete", data: params });
  };
  const DeleteRole = async () => {
    try {
      let dataobj = new FormData();
      dataobj.append("_method", "DELETE");

      const ids = open.type === "all" ? selectedRowIds : [open.data.id];

      const result = await deleteRole({
        id: ids,
        data: dataobj,
      }).unwrap();

      if (result[0]?.data?.success) {
        notifysuccess(result[0]?.data?.message || t("Deleted successfully"));
        setOpen({ status: false, type: "", data: null });

        if (open.type === "all") {
          setSelectedRowIds([]);
        }
      } else {
        notifyError(
          result[0]?.data?.message || t("Some items failed to delete")
        );
      }
    } catch (error) {
      notifyError(error?.data?.message || t("Failed to delete"));
      setOpen({ status: false, type: "", data: null });
    }
  };

  const handleBulkDelete = () => {
    setOpen({ status: true, type: "all", data: null });
  };

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 100 },
    {
      field: "name",
      headerName: t("Name"),
      width: 310,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <div className="boxImageChar">
              <span>{params.row.name.slice(0, 1)}</span>
            </div>
            {params.row.name}
          </div>
        );
      },
    },

    {
      field: "user_count",
      headerName: t("User Count"),
      width: 400,
      renderCell: (params) => {
        return <div className="cellWithImg">{`${params.row.user_count}`}</div>;
      },
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => (
        <ActionIcons
          onEdit={() => handleEdit(params)}
          onView={""}
          onDelete={() => handleDelete(params)}
        />
      ),
    },
  ];

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const goToAddNew = () => {
    navigate(`/roles-permision/add`); // Navigate to edit page
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={roles}
          selectedRowIds={selectedRowIds}
          title="Roles"
          filter="roles"
          AddNewFn={goToAddNew}
          onDelete={handleBulkDelete}
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : isLoading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={roles}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>

        <ModalComponent
          width={open.type === "delete" ? 450 : 600}
          open={open.status}
          onClose={() => setOpen({ status: false, type: "", data: null })}
        >
          {(open.type === "delete" || open.type === "all") && (
            <DeleteItem
              data={open.data}
              onClose={() => setOpen({ status: false, type: "", data: null })}
              title="Role"
              deleteFn={DeleteRole}
              selectedRowIds={selectedRowIds}
              type={open.type}
            />
          )}
        </ModalComponent>
      </div>
    </div>
  );
}

export default MainRoles;
