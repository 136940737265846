import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");

export const chronicDiApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all chronic diseases
    getChronicDiseases: builder.query({
      query: (arg) => ({
        url: "/admin-panal/chronic_diseases",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Chronic Diseases List Response:", response);
        return response.result.chronic_diseases.data;
      },
      providesTags: ["ChronicDiseases"],
    }),

    // Get single chronic disease
    getChronicDiseaseById: builder.query({
      query: (arg) => ({
        url: `/admin-panal/chronic_diseases/${arg.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Chronic Disease Detail Response:", response);
        return response.result;
      },
      providesTags: (result, error, arg) => [
        { type: "ChronicDisease", id: arg.id },
      ],
    }),

    // Create chronic disease
    createChronicDisease: builder.mutation({
      query: (arg) => ({
        url: "/admin-panal/chronic_diseases",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Create Chronic Disease Response:", response);
        return response;
      },
      invalidatesTags: ["ChronicDiseases"],
    }),

    // Update chronic disease
    updateChronicDisease: builder.mutation({
      query: (arg) => ({
        url: `/admin-panal/chronic_diseases/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Update Chronic Disease Response:", response);
        return response;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "ChronicDisease", id: arg.id },
        "ChronicDiseases",
      ],
    }),

    // Delete chronic disease
    deleteChronicDisease: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          // Make separate API calls for each ID
          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/admin-panal/chronic_diseases/${id}`,
              method: "DELETE",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          // Wait for all delete operations to complete
          const results = await Promise.all(deletePromises);
          console.log("Delete Chronic Disease Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["ChronicDiseases"],
    }),
  }),
});

export const {
  useGetChronicDiseasesQuery,
  useGetChronicDiseaseByIdQuery,
  useCreateChronicDiseaseMutation,
  useUpdateChronicDiseaseMutation,
  useDeleteChronicDiseaseMutation,
} = chronicDiApi;
