import {
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import { notifyError, notifysuccess } from "../../Notification";
import {
  useCreateAdminMutation,
  useUpdateAdminMutation,
  useGetAdminByIdQuery,
} from "../../services/adminApi";
import { useGetAdminRolesQuery } from "../../services/commonApi";

function PopUpUsers({ type, handleClose, data }) {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [inp, setInp] = useState({ active: 0 });
  const [val, setVal] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // RTK Query hooks
  const [createAdmin, { isLoading: isCreating }] = useCreateAdminMutation();
  const [updateAdmin, { isLoading: isUpdating }] = useUpdateAdminMutation();
  console.log("datadata", data);
  useEffect(() => {
    if ((type === "edit" || type === "view") && data) {
      setInp(data);
    }
  }, [data, type]);
  const {
    data: singleData,
    isLoading: loadingSingle,
    error: errorSingle,
  } = useGetAdminByIdQuery(
    { id: data?.id, lang: i18n.language },
    { skip: type !== "edit" }
  );

  const { data: roles, isLoading: loadingRoles } = useGetAdminRolesQuery({
    lang: i18n.language,
  });

  console.log("singleDatasingleData", singleData);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const getValue = (e) => {
    setVal(e.target.value);
    setInp((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSwitchChange = (e, name) => {
    setInp((prev) => ({
      ...prev,
      [name]: e.target.checked ? 1 : 0,
    }));
  };

  const onsubmitfn = async (e) => {
    e.preventDefault();
    try {
      if (type === "add") {
        const result = await createAdmin({
          data: inp,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
        }
        handleClose();
      }
      if (type === "edit") {
        const newdata = {
          ...inp,
          _method: "PUT",
        };
        const result = await updateAdmin({
          id: singleData.id,
          data: newdata,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
        }
        handleClose();
      }
    } catch (error) {
      notifyError(error?.data?.message || "An error occurred");
      console.error("Error:", error);
    }
  };

  if (errorSingle) {
    notifyError(errorSingle?.data?.message || "Error loading admin data");
  }

  return (
    <Fragment>
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            borderBottom: "1px solid lightgray",
            paddingBottom: "2px",
            marginBottom: "30px",
          }}
        >
          {type === "edit" ? "Edit Admin" : "Add Admin"}
        </Typography>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row" onSubmit={onsubmitfn}>
            <div className="col-md-6 mb-2">
              <div className="form-group">
                <TextField
                  type="text"
                  label={t("name")}
                  name="name"
                  required
                  defaultValue={type === "edit" ? singleData?.name : ""}
                  onChange={getValue}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6 mb-2">
              <div className="form-group">
                <TextField
                  type="email"
                  label={t("Email")}
                  name="email"
                  required
                  defaultValue={type === "edit" ? singleData?.email : ""}
                  onChange={getValue}
                  variant="standard"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-md-6 mb-2">
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="password">
                  {t("Password")} <sup className="redstar">*</sup>
                </InputLabel>
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  required={type !== "edit"}
                  onChange={getValue}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <div className="col-md-6 mb-2">
              <div className="form-group">
                <p>
                  {t("Status")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  {...label}
                  defaultChecked={type === "edit" && singleData?.active == 1}
                  onChange={(event) => handleSwitchChange(event, "active")}
                />
              </div>
            </div>

            <div className="col-md-12 mb-3">
              <FormControl fullWidth>
                <InputLabel id="role">{t("Role")}</InputLabel>
                <Select
                  labelId="Role"
                  name="role"
                  required
                  defaultValue={type === "edit" ? singleData?.role : val}
                  label="Role"
                  onChange={getValue}
                >
                  {loadingRoles ? (
                    <MenuItem disabled>loading</MenuItem>
                  ) : (
                    roles?.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="btn-lest mt-3">
              {isCreating || isUpdating ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </DialogTitle>
    </Fragment>
  );
}

export default PopUpUsers;
