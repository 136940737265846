// routes.js
import { Navigate } from "react-router-dom";
import Login from "./pages/login/Login";
import AdminHome from "./pages/AdminHome/AdminHome";
import NotFound from "./pages/NotFoundPage/NotFound";
import MainUsers from "./pages/Folder-Users/MainUsers";
import MainChronicD from "./pages/FolderChronicD/MainChronicD";
import MainAccessM from "./pages/Folder-AccessMethod/MainAccessM";
import LanguSpeak from "./pages/Folder-LanguageSpeak/LanguSpeak";
import MainSubscription from "./pages/Folder-Subscription/MainSubscription";
import RoleAndPermision from "./pages/Folder-role/RoleAndPermision";
import RolePermEditAdd from "./pages/Folder-role/RolePermisionEditAdd/RolePermEditAdd";
import MainCompany from "./pages/Folder-Comapny/MainCompany";
import MainCategory from "./pages/Folder-Category/MainCategory";

const useRout = (token, sidebarOpen) => {
  let test = true;
  const routes = [
    { path: "/", element: token ? <AdminHome /> : <Login /> },
    {
      path: "/login",
      element: token ? <Navigate to="/" replace /> : <Login />,
    },
    { path: "/admins", element: <MainUsers /> },
    { path: "roles-permision", element: <RoleAndPermision /> },
    { path: "roles-permision/:name/:id", element: <RolePermEditAdd /> },
    { path: "roles-permision/:name", element: <RolePermEditAdd /> },
    { path: "/companies", element: <MainCompany /> },
    { path: "/subscription", element: <MainSubscription /> },
    { path: "/categories", element: <MainCategory /> },
    { path: "/chronic-diseases", element: <MainChronicD /> },
    { path: "/access-methods", element: <MainAccessM /> },
    { path: "/language-speak", element: <LanguSpeak /> },
    { path: "*", element: <NotFound /> },
  ];
  return routes;
};

export default useRout;
