import React, { useEffect, useState } from "react";
import "./RolePermEditAdd.scss";
import Navbar from "../../../components/Navbar/Navbar";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import NewRoleCheckBox from "../../../components/NewRoleCheckBox/NewRoleCheckBox";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import { notifyError, notifysuccess } from "../../../Notification";
import {
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useGetRoleByIdQuery,
  useGetPermissionsQuery,
} from "../../../services/roleApi";

function RolePermEditAdd() {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { id, name: title } = useParams();
  const [checkValue, setCheckValue] = useState([]);
  const [nameRole, setNameRole] = useState("");
  const [golobalPermissions, setGlobalPermissions] = useState("");

  // RTK Query hooks
  const [createRole, { isLoading: isCreating }] = useCreateRoleMutation();
  const [updateRole, { isLoading: isUpdating }] = useUpdateRoleMutation();

  const {
    data: roleData,
    isLoading: loadingSingle,
    error: errorSingle,
  } = useGetRoleByIdQuery(
    { id, lang: i18n.language },
    { skip: title === "add" }
  );

  const {
    data: permissions,
    isLoading: loadingPermissions,
    error: errorPermissions,
  } = useGetPermissionsQuery();

  const goBackFn = () => {
    navigate(-1);
  };

  // Set initial permissions when data is loaded
  useEffect(() => {
    if (title === "edit" && roleData) {
      const getids = roleData.permissions.map((e) => e.id);
      setCheckValue(getids);
      setNameRole(roleData.name);
    }
  }, [title, roleData]);

  // Format permissions data
  useEffect(() => {
    if (permissions) {
      setGlobalPermissions(
        Object.keys(permissions).map((name) => {
          let permation = permissions[name].map((e) => ({
            name: e.name,
            id: e.id,
            state:
              title === "edit" && roleData
                ? roleData.permissions.some((p) => p.id === e.id)
                : false,
          }));
          return {
            name: name,
            permation,
          };
        })
      );
    }
  }, [permissions, roleData, title]);

  const handleCheack = (e, type, state) => {
    if (type === "single") {
      setCheckValue((prev) => {
        if (prev.includes(e)) {
          return prev.filter((id) => id !== e);
        }
        return [...prev, e];
      });
    } else {
      setCheckValue((prev) => {
        let newValue = [...prev];
        e.forEach((nn) => {
          if (state === "add") {
            if (!newValue.includes(nn)) {
              newValue.push(nn);
            }
          } else {
            newValue = newValue.filter((id) => id !== nn);
          }
        });
        return newValue;
      });
    }
  };

  const SendRole = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", nameRole || roleData?.name);
      checkValue.forEach((permission) => {
        formData.append("permissions[]", permission);
      });

      if (title === "add") {
        const result = await createRole({
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
          goBackFn();
        }
      } else if (title === "edit") {
        formData.append("_method", "PUT");
        const result = await updateRole({
          id: roleData.id,
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
          goBackFn();
        }
      }
    } catch (error) {
      notifyError(error?.data?.message || "An error occurred");
    }
  };

  const isLoading = loadingSingle || loadingPermissions;
  const error = errorSingle || errorPermissions;

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header add-header">
          <h4>{title === "add" ? t("Create Role") : t("Edit Role")}</h4>
          <div className="btn-list">
            <button onClick={goBackFn} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>

        {error ? (
          <ErrorCompo />
        ) : isLoading ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row role-blog" onSubmit={SendRole}>
            <div className="col-md-12">
              <div className="form-group mb-5">
                <label className="text-dark" htmlFor="name">
                  {t("Role Name")}: <sup className="text-danger">*</sup>
                </label>
                <input
                  id="name"
                  required
                  type="text"
                  name="name"
                  onChange={(e) => setNameRole(e.target.value)}
                  defaultValue={roleData?.name || ""}
                  placeholder="Please Enter Role Name"
                  className="form-control"
                />
              </div>
            </div>

            <p className="text-dark col-md-12">
              <b>{t("Assign Permissions to role")}:</b>
            </p>

            <div className="box-roles row">
              {golobalPermissions &&
                golobalPermissions.map((e, index) => (
                  <NewRoleCheckBox
                    key={index}
                    data={e}
                    handleCheack={handleCheack}
                  />
                ))}
            </div>

            <div className="btn-lest">
              <button type="reset" className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Reset")}
              </button>

              {isCreating || isUpdating ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {title === "edit" ? t("Update") : t("Create")}
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default RolePermEditAdd;
