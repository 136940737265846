// ModalComponent.js
import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const ModalComponent = ({
  width,
  open,
  onClose,
  title,
  children,
  padding,
  WidthWd,
  height,
  top,
  IsCloseX,
}) => {
  const [t, i18n] = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        direction: i18n.language === "ar" ? "rtl" : "ltr",
      }}
    >
      <Box
        sx={{
          direction: i18n.language === "ar" ? "rtl" : "ltr",
          padding: padding ? padding : "16px",
          bgcolor: "background.paper",
          maxWidth: width ? width : 400,
          width: WidthWd ? "100vw" : "80vw",
          // margin: "auto",
          // marginTop: "5%  !important",
          // mt: 30,
          height: height ? height : "auto",
          borderRadius: "10px",
          position: "absolute",
          top: top ? top : "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 99999999,
        }}
      >
        <div
          className="wrapper-modal"
          style={{ maxHeight: WidthWd ? "100%" : "620px" }}
        >
          {IsCloseX && (
            <div
              className="Close"
              onClick={() => onClose()}
              style={
                i18n.language == "ar"
                  ? {
                      left: "15px",
                    }
                  : { right: "15px" }
              }
            >
              <CloseIcon />
            </div>
          )}
          {children}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
