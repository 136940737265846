import { DialogTitle, Switch, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import { notifyError, notifysuccess } from "../../Notification";
import CheckMultiLangu from "../../components/CheckMultiLangu/CheckMultiLangu";
import {
  useCreateChronicDiseaseMutation,
  useUpdateChronicDiseaseMutation,
  useGetChronicDiseaseByIdQuery,
} from "../../services/chronicDiApi";

function PopUpChronicD({ type, handleClose, data }) {
  const [t, i18n] = useTranslation();
  const [inp, setInp] = useState({});
  const [selectedLanguages, setSelectedLanguages] = useState(["en"]);

  // RTK Query hooks
  const [createChronicDisease, { isLoading: isCreating }] =
    useCreateChronicDiseaseMutation();
  const [updateChronicDisease, { isLoading: isUpdating }] =
    useUpdateChronicDiseaseMutation();

  const {
    data: singleData,
    isLoading: loadingSingle,
    error: errorSingle,
  } = useGetChronicDiseaseByIdQuery(
    { id: data?.id, lang: i18n.language },
    { skip: type !== "edit" }
  );

  useEffect(() => {
    if ((type === "edit" || type === "view") && singleData) {
      // Extract translations and set default values
      const translations = singleData.item.translations;
      const defaultValues = translations.reduce((acc, translation) => {
        acc[`translations[${translation.locale}][name]`] = translation.name;
        return acc;
      }, {});

      // Update selected languages
      const allLocales = translations.map((translation) => translation.locale);
      setSelectedLanguages([...new Set([...selectedLanguages, ...allLocales])]);
      setInp(defaultValues);
    }
  }, [singleData, type]);

  const getValue = (e) => {
    setInp((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleLanguage = (lang) => {
    setSelectedLanguages((prev) =>
      prev.includes(lang) ? prev.filter((l) => l !== lang) : [...prev, lang]
    );
  };

  const onsubmitfn = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      // Add all translations to FormData
      Object.entries(inp).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (type === "add") {
        const result = await createChronicDisease({
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
          handleClose();
        }
      }
      if (type === "edit") {
        formData.append("_method", "PUT");

        const result = await updateChronicDisease({
          id: singleData.item.id,
          data: formData,
          lang: i18n.language,
        }).unwrap();

        if (result?.message) {
          notifysuccess(result.message);
          handleClose();
        }
      }
    } catch (error) {
      notifyError(error?.data?.message || "An error occurred");
      console.error("Error:", error);
    }
  };

  if (errorSingle) {
    notifyError(
      errorSingle?.data?.message || "Error loading chronic disease data"
    );
  }

  return (
    <Fragment>
      <DialogTitle sx={{ p: 3 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            borderBottom: "1px solid lightgray",
            paddingBottom: "2px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {type === "edit"
            ? t("Edit Chronic Disease")
            : t("Add Chronic Disease")}
          <CheckMultiLangu
            toggleLanguage={toggleLanguage}
            selectedLanguages={selectedLanguages}
          />
        </Typography>

        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="row" onSubmit={onsubmitfn}>
            {selectedLanguages.map((lang) => (
              <div key={lang} className="col-md-12 mb-3">
                <div className="form-group">
                  <TextField
                    type="text"
                    label={t("name") + ` (${lang})`}
                    name={`translations[${lang}][name]`}
                    required
                    value={inp[`translations[${lang}][name]`] || ""}
                    onChange={getValue}
                    variant="standard"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            ))}

            <div className="btn-lest mt-3">
              {isCreating || isUpdating ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </DialogTitle>
    </Fragment>
  );
}

export default PopUpChronicD;
