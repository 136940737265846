import React from "react";
import ModalDelete from "../ModalDelete/ModalDelete";
import ModalMulti from "../ModalMulti/ModalMulti";
import ExportFunctions from "../ExportFunctions/ExportFunctions";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

function HeaderPage({
  data,
  selectedRowIds,
  title,
  filter,
  onDelete,
  AddNewFn,
}) {
  const [t, i18n] = useTranslation();
  return (
    <div className="main-header">
      <h3>{t(title)}</h3>
      <div className="btn-list">
        <ExportFunctions data={data} title="admin" />

        {AddNewFn ? (
          <button className="btn" onClick={() => (AddNewFn ? AddNewFn() : "")}>
            <AddIcon />
            {t("Add New")}
          </button>
        ) : (
          <button className="btn">
            <ModalMulti
              data={data}
              filter={filter}
              type="add"
              title={"Add New"}
            />
          </button>
        )}
        <button className="btn btn-delete">
          {/* <ModalDelete
            filter={filter}
            params={data}
            type="all"
            selectedRowIds={selectedRowIds}
          /> */}
          <div onClick={onDelete} className="d-flex gap-2 align-items-center">
            {<DeleteIcon />}
            {t("Delete Selected")}
          </div>
        </button>
      </div>
    </div>
  );
}

export default HeaderPage;
