import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");

export const rolesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all roles
    getRoles: builder.query({
      query: (arg) => ({
        url: "/admin-panal/roles",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Roles List Response:", response);
        return response.result.roles;
      },
      providesTags: ["Roles"],
    }),

    // Get single role
    getRoleById: builder.query({
      query: (arg) => ({
        url: `/admin-panal/roles/${arg.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Role Detail Response:", response);
        return response.result.item;
      },
      providesTags: (result, error, arg) => [{ type: "Role", id: arg.id }],
    }),

    // Get all permissions
    getPermissions: builder.query({
      query: () => ({
        url: "/core/common/list/permissions",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => {
        console.log("Permissions List Response:", response);
        return response.result;
      },
      providesTags: ["Permissions"],
    }),

    // Create role
    createRole: builder.mutation({
      query: (arg) => ({
        url: "/admin-panal/roles",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Create Role Response:", response);
        return response;
      },
      invalidatesTags: ["Roles"],
    }),

    // Update role
    updateRole: builder.mutation({
      query: (arg) => ({
        url: `/admin-panal/roles/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Update Role Response:", response);
        return response;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Role", id: arg.id },
        "Roles",
      ],
    }),

    // Delete role
    // Delete role
    deleteRole: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          // Make separate API calls for each ID
          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/admin-panal/roles/${id}`,
              method: "DELETE", // Changed from POST to DELETE
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          // Wait for all delete operations to complete
          const results = await Promise.all(deletePromises);
          console.log("Delete Role Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Roles"],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleByIdQuery,
  useGetPermissionsQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = rolesApi;
