import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");

export const adminsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all admins
    getAdmins: builder.query({
      query: (arg) => ({
        url: "/admin-panal/admins",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Admin List Response:", response);
        return response.result.admins.data;
      },
      providesTags: ["Admins"],
    }),

    // Get single admin
    getAdminById: builder.query({
      query: (arg) => ({
        url: `/admin-panal/admins/${arg.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Admin Detail Response:", response);
        return response.result.admin;
      },
      providesTags: (result, error, arg) => [{ type: "Admin", id: arg.id }],
    }),

    // Create admin
    createAdmin: builder.mutation({
      query: (arg) => ({
        url: "/admin-panal/admins",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Create Admin Response:", response);
        return response;
      },
      invalidatesTags: ["Admins"],
    }),

    // Update admin
    updateAdmin: builder.mutation({
      query: (arg) => ({
        url: `/admin-panal/admins/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Update Admin Response:", response);
        return response;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Admin", id: arg.id },
        "Admins",
      ],
    }),

    // Delete admin
    deleteAdmin: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          // Make separate API calls for each ID
          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/admin-panal/admins/${id}`,
              method: "DELETE",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          // Wait for all delete operations to complete
          const results = await Promise.all(deletePromises);
          console.log("Delete Admin Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Admins"],
    }),

    // Change status
    changeStatus: builder.mutation({
      query: (arg) => ({
        url: `/admin-panal/${arg.name}/toggle-active/${arg.id}?active=${arg.active}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => {
        console.log("Change Status Response:", response);
        return response;
      },
      invalidatesTags: ["Admins"],
    }),

    // Update password
    updatePassword: builder.mutation({
      query: (arg) => ({
        url: "/updatePassword",
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        body: arg,
      }),
      transformResponse: (response) => {
        console.log("Update Password Response:", response);
        return response;
      },
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useGetAdminByIdQuery,
  useCreateAdminMutation,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
  useChangeStatusMutation,
  useUpdatePasswordMutation,
} = adminsApi;
