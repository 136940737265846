import { api } from "./api";
import Cookies from "js-cookie";
import { notifyError, notifysuccess } from "../Notification";

const token = Cookies.get("token");
console.log("token", token);
export const companiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get all companies
    getCompanies: builder.query({
      query: (arg) => ({
        url: "/admin-panal/companies",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Companies List Response:", response);
        return response.result.companies.data;
      },
      providesTags: ["Companies"],
    }),

    // Get companies list (for dropdown/select)
    getCompaniesList: builder.query({
      query: (arg) => ({
        url: "/core/common/list/companies",
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Companies List (Dropdown) Response:", response);
        return response.result.list;
      },
      providesTags: ["CompaniesList"],
    }),

    // Get single company
    getCompanyById: builder.query({
      query: (arg) => ({
        url: `/admin-panal/companies/${arg.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
      }),
      transformResponse: (response) => {
        console.log("Company Detail Response:", response);
        return response.result.company;
      },
      providesTags: (result, error, arg) => [{ type: "Company", id: arg.id }],
    }),

    // Create company
    createCompany: builder.mutation({
      query: (arg) => ({
        url: "/admin-panal/companies",
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Create Company Response:", response);
        return response;
      },
      invalidatesTags: ["Companies", "CompaniesList"],
    }),

    // Update company
    updateCompany: builder.mutation({
      query: (arg) => ({
        url: `/admin-panal/companies/${arg.id}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          lang: arg?.lang,
        },
        body: arg.data,
      }),
      transformResponse: (response) => {
        console.log("Update Company Response:", response);
        return response;
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Company", id: arg.id },
        "Companies",
        "CompaniesList",
      ],
    }),

    // Delete company
    deleteCompany: builder.mutation({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const ids = Array.isArray(arg.id) ? arg.id : [arg.id];

          // Make separate API calls for each ID
          const deletePromises = ids.map((id) =>
            fetchWithBQ({
              url: `/admin-panal/companies/${id}`,
              method: "DELETE",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
              body: arg.data,
            })
          );

          // Wait for all delete operations to complete
          const results = await Promise.all(deletePromises);
          console.log("Delete Company Responses:", results);

          return { data: results };
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ["Companies", "CompaniesList"],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompaniesListQuery,
  useGetCompanyByIdQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companiesApi;
