import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import Datatable from "../../components/datatable/Datatable";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import ActionIcons from "../../components/ActionIcons/ActionIcons";
import ModalComponent from "../../utils/ModalComponent";
import DeleteItem from "../../components/DeleteItem/DeleteItem";
import { useNavigate } from "react-router-dom";
import {
  useGetAdminsQuery,
  useChangeStatusMutation,
  useDeleteAdminMutation,
} from "../../services/adminApi";
import { notifyError, notifysuccess } from "../../Notification";
import PopUpUsers from "./PopUpUsers";

function MainUsers() {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [open, setOpen] = useState({ status: false, type: "", data: null });
  console.log("openopenopen", open);
  // RTK Query hooks
  const {
    data: admins,
    isLoading,
    error: errorAll,
  } = useGetAdminsQuery({
    lang: i18n.language,
  });

  const [changeStatus] = useChangeStatusMutation();
  const [deleteAdmin] = useDeleteAdminMutation();

  const handleEdit = (params) => {
    setOpen({ status: true, type: "edit", data: params.row });
  };

  const handleView = (params) => {
    setOpen({ status: true, type: "view", data: params.row });
  };

  const handleDelete = (params) => {
    setOpen({ status: true, type: "delete", data: params });
  };

  const DeleteUser = async () => {
    try {
      let dataobj = new FormData();
      dataobj.append("_method", "DELETE");

      const ids = open.type === "all" ? selectedRowIds : [open.data.id];

      const result = await deleteAdmin({
        id: ids,
        data: dataobj,
      }).unwrap();

      // Check if all deletions were successful
      if (result[0]?.data?.success) {
        notifysuccess(result[0]?.data?.message || t("Deleted successfully"));
        setOpen({ status: false, type: "", data: null });

        if (open.type === "all") {
          setSelectedRowIds([]);
        }
      } else {
        notifyError(
          result[0]?.data?.message || t("Some items failed to delete")
        );
      }
    } catch (error) {
      notifyError(error?.data?.message || t("Failed to delete"));
      setOpen({ status: false, type: "", data: null });
    }
  };

  const handleBulkDelete = () => {
    console.log("testtt=>", selectedRowIds);
    setOpen({ status: true, type: "all", data: null });
  };

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 100 },
    {
      field: "name",
      headerName: t("Name"),
      width: 310,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.img ? (
              <img className="cellImg" src={params.row.img} alt="avatar" />
            ) : (
              <div className="boxImageChar">
                <span>{params.row.name.slice(0, 1)}</span>
              </div>
            )}
            {`${params.row.name}`}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: t("Email"),
      width: 250,
    },
    {
      field: "role",
      headerName: t("Role"),
      width: 150,
      renderCell: (params) => (
        <div className="d-flex justify-content-between align-items-start gap-2 flex-column">
          <div>{params.row.role}</div>
        </div>
      ),
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 120,
      renderCell: (params) => (
        <div className="status">
          <Switch
            {...label}
            onChange={(event) => getStatus(event, params.row.id)}
            defaultChecked={params.row.active == 1}
          />
        </div>
      ),
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => (
        <ActionIcons
          onEdit={() => handleEdit(params)}
          onView={""}
          onDelete={() => handleDelete(params)}
        />
      ),
    },
  ];

  const getStatus = async (e, id) => {
    try {
      const result = await changeStatus({
        name: "admins",
        id: id,
        active: e.target.checked ? 1 : 0,
      }).unwrap();

      if (result?.message) {
        notifysuccess(result.message);
      }
    } catch (error) {
      notifyError(error?.data?.message || "Error changing status");
    }
  };

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const goToAddNew = () => {
    setOpen({ status: true, type: "add", data: null });
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={admins}
          selectedRowIds={selectedRowIds}
          title="Admins"
          filter="users"
          AddNewFn={goToAddNew}
          onDelete={handleBulkDelete}
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : isLoading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={admins}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>

        <ModalComponent
          width={open.type === "delete" ? 450 : 600}
          open={open.status}
          onClose={() => setOpen({ status: false, type: "", data: null })}
        >
          {(open.type === "add" ||
            open.type === "edit" ||
            open.type === "view") && (
            <PopUpUsers
              type={open.type}
              data={open.data}
              handleClose={() =>
                setOpen({ status: false, type: "", data: null })
              }
            />
          )}

          {(open.type === "delete" || open.type === "all") && (
            <DeleteItem
              data={open.data}
              onClose={() => setOpen({ status: false, type: "", data: null })}
              title="Admin"
              deleteFn={DeleteUser}
              selectedRowIds={selectedRowIds}
              type={open.type}
            />
          )}
        </ModalComponent>
      </div>
    </div>
  );
}

export default MainUsers;
