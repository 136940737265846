import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import {
  useGetLanguageSpeaksQuery,
  useUpdateLanguageSpeakMutation,
  useDeleteLanguageSpeakMutation,
} from "../../services/languageApi";
import PopUpLanguSpeak from "./PopUpLanguSpeak";
import { notifyError, notifysuccess } from "../../Notification";
import ActionIcons from "../../components/ActionIcons/ActionIcons";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import ModalComponent from "../../utils/ModalComponent";
import DeleteItem from "../../components/DeleteItem/DeleteItem";
import Navbar from "../../components/Navbar/Navbar";
import ErrorCompo from "../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../components/CircularIndeterminate/CircularIndeterminate";
import Datatable from "../../components/datatable/Datatable";

function LanguSpeak() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [open, setOpen] = useState({ status: false, type: "", data: null });

  // RTK Query hooks
  const {
    data: languageSpeaks,
    isLoading,
    error: errorAll,
  } = useGetLanguageSpeaksQuery({
    lang: i18n.language,
  });

  const [updateLanguageSpeak] = useUpdateLanguageSpeakMutation();
  const [deleteLanguageSpeak] = useDeleteLanguageSpeakMutation();

  const handleEdit = (params) => {
    setOpen({ status: true, type: "edit", data: params.row });
  };

  const handleView = (params) => {
    setOpen({ status: true, type: "view", data: params.row });
  };

  const handleDelete = (params) => {
    setOpen({ status: true, type: "delete", data: params });
  };

  const DeleteLanguageSpeak = async () => {
    try {
      let dataobj = new FormData();
      dataobj.append("_method", "DELETE");

      const ids = open.type === "all" ? selectedRowIds : [open.data.id];

      const result = await deleteLanguageSpeak({
        id: ids,
        data: dataobj,
      }).unwrap();

      if (result[0]?.data?.success) {
        notifysuccess(result[0]?.data?.message || t("Deleted successfully"));
        setOpen({ status: false, type: "", data: null });

        if (open.type === "all") {
          setSelectedRowIds([]);
        }
      } else {
        notifyError(
          result[0]?.data?.message || t("Some items failed to delete")
        );
      }
    } catch (error) {
      notifyError(error?.data?.message || t("Failed to delete"));
      setOpen({ status: false, type: "", data: null });
    }
  };

  const handleBulkDelete = () => {
    setOpen({ status: true, type: "all", data: null });
  };

  const getStatus = async (e, id) => {
    try {
      const formData = new FormData();
      formData.append("active", e.target.checked ? 1 : 0);
      formData.append("_method", "PUT");

      const result = await updateLanguageSpeak({
        id: id,
        data: formData,
        lang: i18n.language,
      }).unwrap();

      if (result?.message) {
        notifysuccess(result.message);
      }
    } catch (error) {
      notifyError(error?.data?.message || "Error changing status");
    }
  };

  const userColumns = [
    { field: "id", headerName: t("ID"), width: 80 },
    {
      field: "name",
      headerName: t("Name"),
      width: 750,
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      filter: false,
      width: 150,
      renderCell: (params) => (
        <ActionIcons
          onEdit={() => handleEdit(params)}
          onView={""}
          onDelete={() => handleDelete(params)}
        />
      ),
    },
  ];

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const goToAddNew = () => {
    setOpen({ status: true, type: "add", data: null });
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={languageSpeaks}
          selectedRowIds={selectedRowIds}
          title="Language Speak"
          filter="languspeak"
          AddNewFn={goToAddNew}
          onDelete={handleBulkDelete}
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : isLoading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={languageSpeaks}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>

        <ModalComponent
          width={open.type === "delete" ? 450 : 600}
          open={open.status}
          onClose={() => setOpen({ status: false, type: "", data: null })}
        >
          {(open.type === "add" ||
            open.type === "edit" ||
            open.type === "view") && (
            <PopUpLanguSpeak
              type={open.type}
              data={open.data}
              handleClose={() =>
                setOpen({ status: false, type: "", data: null })
              }
            />
          )}

          {(open.type === "delete" || open.type === "all") && (
            <DeleteItem
              data={open.data}
              onClose={() => setOpen({ status: false, type: "", data: null })}
              title="Language Speak"
              deleteFn={DeleteLanguageSpeak}
              selectedRowIds={selectedRowIds}
              type={open.type}
            />
          )}
        </ModalComponent>
      </div>
    </div>
  );
}

export default LanguSpeak;
